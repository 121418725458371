module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.8.0_gatsby@5.8.1_graphql@16.6.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/static/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f638e88cf0649bf8bed54ee1f8c48fef"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.8.0_gatsby@5.8.1_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.8.1_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.0.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
